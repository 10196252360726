exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-elections-jsx": () => import("./../../../src/pages/elections.jsx" /* webpackChunkName: "component---src-pages-elections-jsx" */),
  "component---src-pages-gallery-jsx": () => import("./../../../src/pages/gallery.jsx" /* webpackChunkName: "component---src-pages-gallery-jsx" */),
  "component---src-pages-gamers-jsx": () => import("./../../../src/pages/gamers.jsx" /* webpackChunkName: "component---src-pages-gamers-jsx" */),
  "component---src-pages-icpc-jsx": () => import("./../../../src/pages/icpc.jsx" /* webpackChunkName: "component---src-pages-icpc-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pear-jsx": () => import("./../../../src/pages/pear.jsx" /* webpackChunkName: "component---src-pages-pear-jsx" */),
  "component---src-pages-resources-jsx": () => import("./../../../src/pages/resources.jsx" /* webpackChunkName: "component---src-pages-resources-jsx" */),
  "component---src-pages-seminars-jsx": () => import("./../../../src/pages/seminars.jsx" /* webpackChunkName: "component---src-pages-seminars-jsx" */),
  "component---src-pages-team-jsx": () => import("./../../../src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */),
  "component---src-pages-thehub-jsx": () => import("./../../../src/pages/thehub.jsx" /* webpackChunkName: "component---src-pages-thehub-jsx" */)
}

